/*
 * @author: Matthew Juhl
 *
 * IF FORM SUBMIT IS VIA AJAX:
 * This fix is designed to only work with form submissions, if you need this to fire at a different time, you can
 * trigger a submit event $('#form').trigger('submit');
 *
 * Don't forget to null the submit of your form (so it doesn't actually submit)
$('#form').submit(function() {
        return false;
});
 */


(function (w, d, undefined) {
        // This script works as a placeholder polyfill

        var PLACEHOLDER_POLYFILL_APPLIED_CLASS = 'placeholder_polyfill_applied';

        $(function () {

                var support = 'placeholder' in d.createElement('input');

                if (!support) {

                        function attachPolyfillEvents () {
                                var $this = $(this);
                                var placeholder_text = $this.attr('placeholder');
                                var original_value = $this.val();

                                if (!placeholder_text || !placeholder_text.length) {
                                        return;
                                }

                                $this.addClass('has_placeholder_polyfill');

                                $this.bind('focus', focusListener);
                                $this.bind('blur', blurListener).trigger('blur');

                                var $form = $this.closest('form');

                                if (!$form.data('placeholder_polyfill_attached')) {
                                        $form.data('placeholder_polyfill_attached', 1);
                                        $form.bind('submit', submitListener);
                                        $form.bind('validation_failed', replacePlaceholders);
                                        $form.bind('ajax_complete', replacePlaceholders);
                                        $form.bind('save_form', saveFormListener);
                                }
                        };

                        function saveFormListener(saveform) {
                                //needs to remove placeholders from empty fields so they are not saved
                                //essentially same as submit listener
                                // intercepts submit events for forms with polyfill
                                var $form = $(this);

                                // unapply all placeholder pollyfills before submitting
                                $form.find('.has_placeholder_polyfill').each(function () {
                                        var $this = $(this);

                                        if ($this.data('placeholder_polyfill_applied')) {
                                                unapplyPlaceholder($this);
                                        }
                                });
                        }

                        function replacePlaceholders () {
                                //reapplies placeholders on the form if validation fails and the fields are empty
                                $(this).find('input, textarea').each(function () {
                                        var $this = $(this);
                                        if ($this.val() == '') {
                                                applyPlaceholder($this);
                                        }
                                });
                        }

                        function applyPlaceholder ($el) {
                                $el.val($el.attr('placeholder'));
                                $el.data('placeholder_polyfill_applied', 1);
                                $el.addClass(PLACEHOLDER_POLYFILL_APPLIED_CLASS);
                        };

                        function unapplyPlaceholder ($el) {
                                $el.val('');
                                $el.data('placeholder_polyfill_applied', 0);
                                $el.removeClass(PLACEHOLDER_POLYFILL_APPLIED_CLASS);
                        };

                        function focusListener (focus) {
                                // remove the placeholder polyfill
                                var $this = $(this);
                                if ($this.data('placeholder_polyfill_applied')) {
                                        unapplyPlaceholder($this);
                                }
                        };

                        function blurListener (blur) {
                                // on blur, apply the placeholder if value is empty
                                var $this = $(this);
                                if ($this.val() == '') {
                                        applyPlaceholder($this);
                                }
                        };

                        function submitListener (submit) {
                                // intercepts submit events for forms with polyfill
                                var $form = $(this);

                                // unapply all placeholder pollyfills before submitting
                                $form.find('.has_placeholder_polyfill').each(function () {
                                        var $this = $(this);

                                        if ($this.data('placeholder_polyfill_applied')) {
                                                unapplyPlaceholder($this);
                                        }
                                });
                        };

                        $('input, textarea').each(function () {
                                // abracadabra
                                attachPolyfillEvents.apply(this);
                        });
                }
        });

})(this, document);
